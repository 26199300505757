import React from "react"
import Seo from "../components/Seo"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { GoLinkExternal } from "react-icons/go"
import { AiTwotoneFile } from "react-icons/ai"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Gallery from "../components/Gallery"
import Title from "../components/atoms/Title"

const SectorPage = ({ data: { sector } }) => {
  return (
    <>
      <Seo title="Adtrak Sector Hub" />
      <section className="bg-secondary-default dark:bg-secondary-dark">
        <div className="container w-full p-8 md:p-16">
          <h1 className="text-center text-white">{sector.title}</h1>
          <div className="container flex flex-row flex-wrap justify-center w-full gap-2 mt-8 text-3xs md:text-2xs md:gap-4 dark:text-secondary-light">
            {sector.pageBuilder.map((block, id) => (
              <AnchorLink
                className="px-4 py-2 text-current border rounded-full text-secondary-light border-secondary-light hover:bg-secondary-dark dark:text-secondary-light dark:border-secondary-light dark:hover:bg-secondary-dark"
                to={"#" + block.sectionId}
              >
                {block.title}
              </AnchorLink>
            ))}
          </div>
        </div>
      </section>
      <section className="w-full">
        <div className="flex flex-col w-full">
          {sector.pageBuilder.map((block, id) => (
            <div
              key={id}
              id={block.sectionId}
              className="relative p-8 md:p-16 lg:py-40"
            >
              <div className="absolute bottom-0 left-0 w-full h-40 opacity-5 dark:opacity-20 bg-gradient-to-t from-secondary-dark"></div>
              <div className="container">
                {block.model.apiKey === "gallery" && (
                  <div className="w-full">
                    <Title title={block.title} />
                    <div className="grid grid-cols-1 gap-16 mt-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                      <Gallery images={block.gallery} />

                    </div>
                  </div>
                )}
                {block.model.apiKey === "gallery_with_link" && (
                  <div className="w-full">
                    <Title title={block.title} />
                    <div className="grid grid-cols-1 gap-16 mt-8 lg:gap-40 xl:grid-cols-2">
                      {block.gallery.map((item, iid) => (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex flex-col group"
                          href={item.link}
                          key={iid}
                        >
                          <div className="flex flex-row gap-8 mb-4">
                            {item.imagePortrait && (
                              <div className="relative flex w-1/5 p-2 pt-8 mx-auto transition-all duration-300 group-hover:opacity-80 dark:to-secondary-dark rounded-xl bg-gradient-to-tr from-gray-900 to-gray-600">
                                <div className="absolute top-0 w-2 h-2 mt-4 transform -translate-x-1/2 bg-black rounded-full left-1/2"></div>
                                <GatsbyImage
                                  className="w-full h-full bg-cover rounded-lg"
                                  image={item.imagePortrait.gatsbyImageData}
                                  alt={sector.title}
                                />
                              </div>
                            )}
                            {item.imageLandscape && (
                              <div className="relative flex w-4/5 p-2 pb-12 mx-auto transition-all duration-300 group-hover:opacity-80 dark:to-secondary-dark rounded-xl bg-gradient-to-r from-gray-900 to-gray-600">
                                <svg
                                  width="30"
                                  height="31"
                                  viewBox="0 0 30 31"
                                  fill="none"
                                  className="absolute bottom-0 w-4 h-4 mb-4 transform -translate-x-1/2 opacity-30 left-1/2"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M30 1.13522V29.7399H25.3474L24.7862 26.2623C22.2447 28.8834 18.6995 30.4542 14.611 30.4542C6.25 30.4512 0 24.0105 0 15.4391C0 6.84345 6.25 0.454224 14.611 0.454224C18.747 0.454224 22.3278 2.04623 24.8753 4.7127L25.5493 1.13522H30ZM24.2191 15.4391C24.2191 10.0487 20.3533 6.0535 15.0653 6.0535C9.77732 6.0535 5.87292 10.0819 5.87292 15.4391C5.87292 20.7932 9.77435 24.8247 15.0653 24.8247C20.3563 24.8247 24.2191 20.8295 24.2191 15.4391Z"
                                    fill="#fff"
                                  />
                                  <path
                                    d="M15 20C17.7614 20 20 17.7614 20 15C20 12.2386 17.7614 10 15 10C12.2386 10 10 12.2386 10 15C10 17.7614 12.2386 20 15 20Z"
                                    fill="#fff"
                                  />
                                </svg>
                                <GatsbyImage
                                  className="flex w-full h-full bg-cover rounded-lg"
                                  image={item.imageLandscape.gatsbyImageData}
                                  alt={sector.title}
                                />
                              </div>
                            )}
                          </div>
                          <div className="mt-auto">
                            <div className="flex flex-row items-center justify-center gap-2 mx-auto text-sm text-center text-secondary-default dark:text-secondary-light group-hover:underline">
                              <span>{item.title}</span> <GoLinkExternal />
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                )}
                {block.model.apiKey === "links_only" && (
                  <div className="w-full">
                    <Title title={block.title} />
                    <div className="grid grid-cols-1 gap-16 mt-8 md:grid-cols-2 xl:grid-cols-3">
                      {block.links.map((item) => (
                        <>
                          {item.model.apiKey === "document_file" && (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              className="flex flex-row items-center gap-2 hover:underline"
                              href={item.file.url}
                            >
                              <span>{item.title}</span>{" "}
                              <AiTwotoneFile className="opacity-50" />
                            </a>
                          )}
                          {item.model.apiKey === "document_url" && (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              className="inline-flex flex-row items-center gap-2 hover:underline"
                              href={item.url}
                            >
                              <span>{item.title}</span>{" "}
                              <GoLinkExternal className="opacity-50" />
                            </a>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                )}
                {block.model.apiKey === "video_gallery" && (
                  <div className="w-full">
                    <Title title={block.title} />
                    <div className="grid w-full grid-cols-1 gap-16 lg:grid-cols-2">
                      {block.videos.map((video, vid) => (
                        <div className="flex flex-col gap-8">
                          <h3>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              className="inline-flex flex-row items-center gap-2 hover:underline"
                              href={video.link}
                            >
                              <span>{video.title}</span>{" "}
                              <GoLinkExternal className="opacity-50" />
                            </a>
                          </h3>
                          <iframe
                            className="w-full h-[400px]"
                            src={
                              "https://fast.wistia.net/embed/iframe/" +
                              video.videoCode +
                              "?videoFoam=true"
                            }
                            title={video.title}
                            allow="autoplay; fullscreen"
                            allowtransparency="true"
                            frameborder="0"
                            scrolling="no"
                            name="wistia_embed"
                            allowfullscreen
                            msallowfullscreen
                            width="100%"
                            height="100%"
                          ></iframe>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  )
}

export default SectorPage

export const query = graphql`
  query SectorQuery($slug: String!) {
    sector: datoCmsSector(slug: { eq: $slug }) {
      id
      title
      pageBuilder {
        ... on DatoCmsGallery {
          model {
            apiKey
          }
          gallery {
            gatsbyImageData(height: 300, layout: CONSTRAINED)
            url
          }
          sectionId
          title
        }
        ... on DatoCmsGalleryWithLink {
          model {
            apiKey
          }
          title
          sectionId
          gallery {
            imageLandscape {
              gatsbyImageData(height: 600)
            }
            imagePortrait {
              gatsbyImageData(height: 600)
            }
            link
            title
          }
        }
        ... on DatoCmsLinksOnly {
          model {
            apiKey
          }
          title
          sectionId
          links {
            ... on DatoCmsDocumentFile {
              model {
                apiKey
              }
              title
              file {
                url
              }
            }
            ... on DatoCmsDocumentUrl {
              model {
                apiKey
              }
              url
              title
            }
          }
        }
        ... on DatoCmsVideoGallery {
          model {
            apiKey
          }
          title
          sectionId
          videos {
            title
            videoCode
            link
          }
        }
      }
    }
  }
`
