import React, { useState } from "react"
import FsLightbox from "fslightbox-react"
import { GatsbyImage } from "gatsby-plugin-image"

function Gallery({ images }) {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  })

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    })
  }

  const imagesArray = []

  images.map((image) => imagesArray.push(image.url))

  return (
    <>
      {images.map((item, gid) => (
        <GatsbyImage
          onClick={() => openLightboxOnSlide(gid + 1)}
          id={gid}
          className="w-full h-full transition-all duration-300 bg-cover rounded-lg cursor-pointer hover:opacity-75"
          image={item.gatsbyImageData}
          alt={"Hello"}
        />
      ))}
      <FsLightbox
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={imagesArray}
      />
    </>
  )
}

export default Gallery
